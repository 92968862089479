import "styles/pages/single-offer.scss";

import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";

import Layout from "components/Layout";
import ImagesBar from "components/ImagesBar";
import Breadcrumbs from "components/Breadcrumbs";

const SingleOffer = ({ pageContext }) => {
	const header_setup = {
		logo: "logo_black.svg",
		fontColor: "#000000",
		navbarColor: "#ffffff",
	};

	let pageData;
	let parent;
	if (pageContext.data.offerUchmanski) {
		pageData = pageContext?.data?.offerUchmanski;
		parent = "Maciej Uchmański";
	} else {
		pageData = pageContext?.data?.offerFilmum;
		parent = "Studio Filmum";
	}

	const seo = pageContext.seo;

	return (
		<StaticQuery
			query={graphql`
				{
					allWp {
						nodes {
							id
							optionsPage {
								global {
									globalGallery {
										galleryImg {
											altText
											sourceUrl
										}
									}
								}
							}
						}
					}
				}
			`}
			render={(data) => (
				<Layout seo={seo} header_setup={header_setup}>
					<section className="single-offer">
						<div className="single-offer__wrapper">
							<div className="container-fluid">
								<div className="section-title">
									{pageData.offerTitle}
								</div>
								<div className="row">
									<div className="col-lg-8 single-offer__col-left">
										<Breadcrumbs
											current={pageData.offerTitle}
											parent={parent}
										/>
										<div
											dangerouslySetInnerHTML={{
												__html: pageData.offerOver,
											}}
										/>
										<div className="single-offer__redbox">
											<img
												src={
													require("assets/images/warning.svg")
														.default
												}
												alt=""
											/>
											<div
												dangerouslySetInnerHTML={{
													__html: pageData.offerRedbox,
												}}
											/>
										</div>
										<div
											dangerouslySetInnerHTML={{
												__html: pageData.offerBelow,
											}}
										/>
									</div>
									<div className="col-lg-4 single-offer__col-right">
										{pageData?.offerAside?.map(
											(img, index) => {
												return (
													<img
														key={index}
														className="img-fluid"
														src={
															img?.offerAsideImg
																?.sourceUrl
														}
														alt={
															img?.offerAsideImg
																?.altText
														}
													/>
												);
											}
										)}
									</div>
								</div>
								<div className="row">
									<div className="col-lg-8 single-offer__cta">
										<Link to="/oferta/">
											<div className="btn-main">
												Wróć do oferty
											</div>
										</Link>
										<Link to="/kontakt/">
											<div className="btn-main">
												Skontaktuj się ze mną
											</div>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</section>

					<ImagesBar
						images={
							data?.allWp.nodes[0]?.optionsPage?.global
								?.globalGallery
						}
					/>
				</Layout>
			)}
		/>
	);
};

export default SingleOffer;

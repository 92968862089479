import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

const Breadcrumbs = ({ current, parent }) => {
	return (
		<div className="breadcrumbs">
			<div className="container">
				<Link
					to={`/oferta/${
						parent === "Studio Filmum" ? "?tab=studio" : ""
					}`}
				>
					Oferta
				</Link>
				<Link
					to={`/oferta/${
						parent === "Studio Filmum" ? "?tab=studio" : ""
					}`}
				>
					{parent}
				</Link>
				<span>{current}</span>
			</div>
		</div>
	);
};

export default Breadcrumbs;
